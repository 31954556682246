module.exports = [
  { platform: 'X', url: 'https://twitter.com/fastcompany' },
  {
    platform: 'LinkedIn',
    url: 'https://www.linkedin.com/company/fast-company'
  },
  { platform: 'Facebook', url: 'https://www.facebook.com/FastCompany/' },
  { platform: 'Instagram', url: 'https://www.instagram.com/fastcompany/' },
  { platform: 'YouTube', url: 'https://www.youtube.com/user/FastCompany' }
];
