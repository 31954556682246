module.exports = [
  [
    {
      label: 'Advertise',
      url: 'https://www.fastcompany.com/90516811/fast-company-mediakit'
    },
    {
      label: 'Careers',
      url: 'https://www.mansueto.com/careers/'
    },
    {
      label: 'Subscribe',
      url:
        'https://www.fastcompany.com/subscribe?utm_source=footer&utm_medium=button&utm_campaign=bottom'
    },
    {
      label: 'Privacy Policy',
      url: 'https://www.mansueto.com/privacy-policy/'
    }
  ],
  [
    {
      label: 'Terms',
      url: 'https://www.mansueto.com/terms-of-service'
    },
    {
      label: 'Your Privacy Choices',
      url: 'https://fastcompany.zendesk.com/hc/en-us/requests/new'
    },
    {
      label: 'Notice of Collection',
      url: 'https://www.mansueto.com/privacy-policy/#collection'
    }
  ],
  [
    {
      label: 'Permissions',
      url: 'https://kudos.fastcompany.com'
    },
    {
      label: 'Help Center',
      url: '/contact-us'
    },
    {
      label: 'About Us',
      url: '/about-us'
    },
    {
      label: 'Site Map',
      url: '/sitemap'
    }
  ]
];
