import React from 'react';
import { trackGAEvent } from '../../../client/utils/third_party_trackers/ga';
import EventModel from '../../../utils/tracking/models';
import content from './footer_data';
import social from './social_data';
import SocialIcon from './icons';
import clsx from 'clsx';

const Footer = () => (
  <footer className={clsx('footer')}>
    <div className={clsx('footer__social')}>
      {social.map(({ platform, url }, index) => (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            const clickEvent = new EventModel(
              'Footer Click',
              `nav${platform}`,
              'footerFollow',
              `footerNavigation ${platform}`,
              index + 1
            );

            clickEvent.addToDataLayer('Footer Click');

            trackGAEvent({
              eventCategory: 'Social',
              eventAction: 'Footer',
              eventLabel: platform
            });
          }}
        >
          <SocialIcon type={platform} />
        </a>
      ))}
    </div>

    <div className={clsx('footer__copyright')}>
      {`Fast Company & Inc © ${new Date().getFullYear()} Mansueto Ventures, LLC`}
      <a
        href="//www.mansueto.com/vendor-policies"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="footer__daa-logo"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAANCAAAAAC4QtCeAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAAJ0Uk5TAAB2k804AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAAEgAAABIAEbJaz4AAACASURBVAjXYyir3PaDAQby09KL9/2FcjIWf1mbVX0Mwklfw8DwbF5awzkwZzXDjco/j/vTuq+COe93APXcLE5bCeKczvzOwPCoPG0xiHMq6/f9SWkdF4HK1jKcyepJazgFNnrBpwtpVUchRuempR17+htqaU/55l9w5xzf+RHOBgC94zlXyQTR6wAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxMy0wMy0wOFQxOToxMDo0NC0wNTowMNnS5vEAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTMtMDMtMDhUMTk6MTA6NDQtMDU6MDCoj15NAAAAAElFTkSuQmCC"
          alt="Digital Advertising Alliance (DAA) Self-Regulatory Program"
          target="_new"
        />
      </a>
    </div>

    <div className={clsx('footer__links')}>
      {content.map(links => (
        <div className={clsx('footer__links--segment')}>
          {links.map(({ label, url }) => {
            const externalLink = /^http(s)?:\/\//gi.test(url);
            if (label === 'Your Privacy Choices') {
              return <div id="us-nat-optout" />;
            }
            return (
              <a
                href={url}
                {...externalLink && {
                  target: '_blank',
                  rel: 'noopener noreferrer'
                }}
              >
                {label}
              </a>
            );
          })}
        </div>
      ))}
    </div>
    <div className={clsx('footer__newsguard')}>
      <a href="https://www.newsguardtech.com/ratings/rating-process-criteria/">
        <img
          className={clsx('footer__newsguard--logo')}
          src="https://assets.fastcompany.com/image/upload/v1704257048/NewsGuard_Logo_3x.svg"
          alt=""
        />
      </a>
      <div className={clsx('footer__newsguard--statement')}>
        {' '}
        <img
          className={clsx('footer__newsguard--logo')}
          src="https://assets.fastcompany.com/image/upload/v1704257017/100_100-shield-ratings-transparent_2_1_2x.svg"
          alt=""
        />{' '}
        Fastcompany.com adheres to NewsGuard’s nine standards of credibility and
        transparency.{' '}
      </div>
      <a href="https://www.newsguardtech.com/ratings/rating-process-criteria/">
        {' '}
        Learn More{' '}
      </a>
    </div>
  </footer>
);

export default Footer;
